@import '../../NavigationDrawer/navigationDrawer.variables.scss';

.enter {
  transition: width 250ms ease-in;
  width: $navigation-width-collapsed !important;
  transition-delay: 250ms;
}
.enter.enterActive {
  transition: width 250ms ease-in;
  width: $navigation-width-expanded !important;
  transition-delay: 250ms;
}

.exit {
  width: $navigation-width-collapsed !important;
  transition: width 250ms ease-in;
}

.exitDone {
  transition: width 250ms ease-in;
  width: $navigation-width-collapsed !important;
}
