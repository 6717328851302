@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: none;

@media (max-width: velo-breakpoint(S)) {
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
padding: 0 0.5rem 0 1rem;
}
}