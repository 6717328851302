@import 'velo-variables';
@import 'velo-mixins';
@import '../ViewHeight/mixins';
@import './variables';

.container {
  @include vh(height, 100);

  display: flex;
  flex-direction: column;
  background-color: velo-color('token-color-system-page-background');
}

.header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: velo-color('token-color-system-page-background');
  min-height: $header-height-desktop;
  z-index: $velo-base-index-default;

  @supports not (text-orientation: mixed) {
    /* Edge only @see https://caniuse.com/#feat=css-text-orientation */
    justify-content: space-around;
  }

  @media (max-width: velo-breakpoint(XS)) {
    min-height: $header-height-mobile;
  }

  &Hidden {
    @media (max-width: velo-breakpoint(XS)) {
      display: none;
    }

    .progress {
      visibility: hidden;
    }
  }
}

.body {
  @include smooth-scrolling;

  height: 100%;
}

.content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem 1rem 1rem;
}

.logo,
.spacer {
  flex-grow: 0;
  width: $logo-width;

  @media (max-width: $logo-breakpoint) {
    display: none;
  }
}

.progress {
  flex-grow: 1;
  max-width: $content-width;

  @media (max-width: $logo-breakpoint) {
    padding: 0 1rem;
  }

  &Bar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: velo-breakpoint(XS)) {
      flex-direction: row;
      align-items: center;
    }

    &Label {
      margin-top: 0.25rem;

      @media (max-width: velo-breakpoint(XS)) {
        margin-top: 0;
        margin-left: 0.5rem;
        flex: 1 0 auto;
        text-align: right;
        font-size: 0.75rem;
      }
    }
  }
}

@mixin title {
  text-align: center;
  word-break: break-word;
}

.title {
  @include title;

  margin: 0 0 2rem 0;

  &Loading {
    max-width: 500px;
  }
}

.subtitle {
  @include title;

  margin-bottom: 1.5rem;

  @media (max-width: velo-breakpoint(XS)) {
    margin-bottom: 1rem;
  }

  &Loading {
    max-width: 300px;
  }
}

.button {
  width: $button-width;

  @media (max-width: velo-breakpoint(XS)) {
    width: 100%;
  }
}

.footer {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
}

.submitError {
  display: flex;
  justify-content: center;

  &Message {
    background-color: velo-color('token-color-system-error-lighter');
    padding: 1rem;
    margin-top: 1.5rem;
    border-radius: 4px;
    width: 100%;
    text-align: center;
    font-weight: 400;

    @media (max-width: velo-breakpoint(XS)) {
      margin-top: 1rem;
      text-align: left;
    }
  }
}
