.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
justify-content: center;
}