@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-top: 1rem;
display: flex;
justify-content: space-between;

@include from-breakpoint(XS) {
justify-content: flex-end;
}

@include from-breakpoint(S) {
margin-top: unset;
}
}