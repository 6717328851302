@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
left: 0;
top: 0;
right: 0;
bottom: 0;
background-color: velo-color('token-color-system-page-background');
opacity: 0.6;
z-index: $velo-base-index-default;

&.transparent {
opacity: 0;
}
}