@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: none;
width: 100px;

@include from-breakpoint(XS) {
display: block;
}
}