@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@media (max-width: velo-breakpoint(XS)) {
display: none;
}
}