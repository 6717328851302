@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include smooth-scrolling;

width: 100%;
height: 100%;
}