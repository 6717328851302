@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

color: velo-color('token-color-text-default');
@include icon-size(2.5rem);

margin-bottom: 0.25rem;

&.checked {
color: velo-color('token-color-brand-primary');
}
}