// this has to be here to reset the font-family to benton-sans
@import 'velo-variables';

/**
 * Copied from mdc-typography
 */
$velo-typography-font-weight-values: (
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 500,
  black: 900,
) !default;

/**
 * Returns a named font-weight.
 */
@mixin velo-font-weight($weight) {
  font-weight: map-get($velo-typography-font-weight-values, $weight);
}
