@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

&.error {
color: velo-color('token-color-system-error-default');
}

&.disabled {
opacity: 0.4;
}
}