$animation-time: 150ms;

.enter {
  transform: translateX(100%);
}

.enterActive {
  transform: translateX(0%);
  transition: transform $animation-time ease-in-out;
}

.exit {
  transform: translateX(0%);
}

.exitActive {
  transform: translateX(100%);
  transition: transform $animation-time ease-in-out;
}
