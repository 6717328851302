@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

cursor: pointer;

:global(.ds-icon) {
color: velo-color('token-color-text-link-default');
}

&:focus {
outline: none;
}

&.padded {
margin: 0.2rem 0;
padding: 0.8rem 0;
display: flex;
align-items: center;
}

&.offsetTop {
margin-top: -0.8rem;
padding: 0.8rem 0;
margin-bottom: 0.2rem;
}
}