$animation-time: 250ms;

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity $animation-time linear;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity $animation-time linear;
}
