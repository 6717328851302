@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

color: velo-color('token-color-brand-primary');
background-color: velo-color('token-color-brand-primary-background');
border: 1px solid velo-color('token-color-border-disabled');
border-radius: 0.25rem;
display: flex;
flex-direction: row;
align-items: center;
cursor: pointer;
outline: none;
width: 100%;
margin: 0;
padding: 0.5rem 0.5rem 0.5rem 1rem;
height: 4rem;

@media (max-width: velo-breakpoint(XS)) {
justify-content: center;
}

&.checked {
border-color: velo-color('token-color-brand-primary');
}

&.disabled {
border: 1px solid velo-color('token-color-border-disabled');
opacity: 0.4;
cursor: default;
}

/* https://github.com/material-components/material-components-web/issues/5044 */
&:global(.mdc-ripple-surface--primary::before),
&:global(.mdc-ripple-surface--primary::after) {
background-color: velo-color('token-color-brand-primary');
}
}