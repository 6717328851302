@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
padding: 0.75rem;

&.type-warning {
background-color: velo-color('token-color-system-warning-lighter');
}

&.type-success {
background-color: velo-color('token-color-system-success-lighter');
}

&.type-info {
background-color: velo-color('token-color-system-info-lighter');
}

&.type-error {
background-color: velo-color('token-color-system-error-lighter');
}

&.type-unknown {
background-color: velo-color('token-color-system-unknown');
}

&.type-hibanainfo {
background-color: #ebf8eb;
}

&.type-skeleton {
@include skeleton-background();
}
}