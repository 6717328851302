@import '@design-system/tokens/typography/scss/mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include ds-typography-ellipsis;

flex-grow: 2;
margin: 0;
}