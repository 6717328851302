@import 'velo-variables';
@import '../navigationDrawerItem';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@extend %item;

padding-left: 2rem;
&.mobile {
padding-left: 0.5rem;
}

&:hover {
cursor: pointer;
}

&:hover:before {
background-color: velo-color('token-color-text-link-bg-intent') !important;
opacity: 0.12 !important;
}

&.activated {
i {
color: velo-color('token-color-brand-primary');
}
}
}