@import '../ViewHeight/mixins';
@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include vh(min-height, 100);
@include smooth-scrolling;

background: velo-color('token-color-system-page-surface');
display: flex;
align-items: center;
justify-content: center;

@include smooth-scrolling;
}