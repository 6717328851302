@import '@material/animation/functions';
@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

$default-background-color: velo-color('token-color-system-warning-lighter');
$default-color: velo-color('token-color-text-default');
$notification-height: 50px;

background-color: $default-background-color;
color: $default-color;
width: auto;
min-height: $notification-height;
padding: 0 0 0 1rem;
position: absolute;
display: flex;
flex-direction: row;
justify-content: space-between;
left: 0;
right: 0;
top: 0;
word-break: break-word;
z-index: $velo-desktop-in-front-index-default;

@media (max-width: velo-breakpoint(XS)) {
max-height: inherit;
min-height: $notification-height;
}

&.type-failure {
background-color: velo-color('token-color-system-error-default');
color: velo-color('token-color-text-inverse');
:global(.token-typography__body-text) {
font-weight: 500;
}
:global(.velo-notification-status-icon) {
color: velo-color('token-color-text-inverse');
}
}

&.type-warning {
background-color: velo-color('token-color-system-warning-lighter');
color: $default-color;
:global(.velo-notification-status-icon) {
color: velo-color('token-color-system-warning-default');
}
}

&.type-success {
background-color: velo-color('token-color-system-success-lighter');
color: $default-color;

:global(.velo-notification-status-icon) {
color: velo-color('token-color-system-success-default');
}
}

&.type-info {
background-color: velo-color('token-color-system-info-lighter');
color: $default-color;
:global(.velo-notification-status-icon) {
color: velo-color('token-color-system-info-default');
}
}

&.type-payee {
opacity: 0.95;
background-color: velo-color('token-color-system-warning-default');
color: velo-color('token-color-text-inverse');
animation: slideUp 1s ease;
transform: translateY(-100%);

@media (max-width: velo-breakpoint(XS)) {
/* Take the fixed mobile header into account. */
top: -65px;
transform: translateY(-130%);
}
}

&.display-animIn {
display: flex;
animation: slideDown 1s ease;
transform: translateY(0%);
}

&.display-animOut {
display: flex;
animation: slideUp 1s ease;
transform: translateY(-100%);
}

&.display-hide {
display: none;
}
}