@import 'velo-mixins';
@import '@material/elevation/mixins';
@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include mdc-elevation(24);

position: fixed;
top: 0;
right: 0;
height: 100%;
width: 500px;
border-radius: 0;
display: flex;
flex-direction: column;
z-index: $velo-over-all-default;

@media (max-width: velo-breakpoint(XS)) {
left: 0;
width: 100%;
min-width: 100%;
}
}