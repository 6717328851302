@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

color: velo-color('token-color-text-inverse');
padding: 1rem;
width: 100%;
text-align: center;

@media (max-width: velo-breakpoint(XS)) {
text-align: left;
padding-right: 0;
}
}