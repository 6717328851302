@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 130px;
height: 130px;
margin: 10px;
@include skeleton-background();
}