.cls1 { /*!*/ }
.cls2 {
composes: cls1;

height: 2.25rem;
width: 8rem;

&.fullWidth {
width: 100%;
}
}