@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
cursor: pointer;
align-items: center;
justify-content: space-between;
height: 3rem;
padding-left: 1rem;
padding-right: 1rem;

&:hover,
&:focus {
background-color: velo-color('token-color-brand-primary-background');
outline: none;
}

&.compact {
padding-left: 0;
padding-right: 0;
}
}