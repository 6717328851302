// web-portal CSS overrides go here.
@import 'velo-variables';

.mdc-select {
  option:checked {
    // Ensures the selected item is visible
    display: block;
  }
}

/* FIXME: move to VeloTextField component */
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid) {
  .mdc-text-field__input:hover {
    & ~ .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: velo-color('token-color-border-hover') !important;
      }
    }
  }

  .mdc-text-field__icon:hover {
    & ~ .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: velo-color('token-color-border-hover') !important;
      }
    }
  }
}

/* FIXME: move to VeloSelect component */
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused):not(.mdc-text-field--invalid) {
  .mdc-select__native-control:hover,
  .mdc-select__selected-text:hover {
    & ~ .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: velo-color('token-color-border-hover') !important;
      }
    }
  }

  .mdc-select__dropdown-icon:hover {
    & ~ .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: velo-color('token-color-border-hover') !important;
      }
    }
  }
}

.mdc-select--disabled.mdc-select--outlined .mdc-select__native-control {
  color: velo-color('token-color-text-disabled');
}

/* FIXME: move to SelectCountry component */
.mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
  color: velo-color('token-color-text-default') !important;
}

/* FIXME: override SVG for MDC select component + remove dark mode selector */
.mode_dark .mdc-select__dropdown-icon {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid velo-color('token-color-border-default');
  width: 0;
  height: 0;
  right: 15px;
  bottom: 25px;
}
