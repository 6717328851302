@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

/* expand for extra options */
height: 12.9rem;

@media (max-width: velo-breakpoint(XS)) {
height: 23rem;
width: 100%;
flex-grow: 1;
border-bottom: 1px solid velo-color('token-color-system-divider');
margin: 0 0 2rem;
}
}