@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-top: 0.4rem;

@media (max-width: velo-breakpoint(XS)) {
margin-top: 0.25rem;
margin-left: 0.5rem;
flex: 1 0 auto;
text-align: right;
font-size: 0.75rem;
}
}