@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-top: 1rem;
margin-bottom: 1.5rem;
border-radius: 4px;
padding: 0;
&.slimline {
margin-left: 1rem;
margin-right: 1rem;
@media (min-width: velo-breakpoint(XS)) {
margin-left: 1rem;
margin-right: 1rem;
}
}
}