@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

z-index: $velo-over-all-default;
position: fixed;
left: 0;
top: 0;
height: 100%;
width: 100%;
background-color: velo-color('token-color-system-overlay');

&.transparent {
opacity: 0;
}
}