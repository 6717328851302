@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

.fullWidth {
display: flex;
align-items: flex-start;
flex: 1 0 auto;
flex-direction: column;

@include from-breakpoint(XS) {
flex-direction: row;
justify-content: space-between;
align-items: center;
}
}

font-family: var(--ds-typography-font-family);
}