.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
justify-content: space-between;
padding: 0;
margin: 0;
padding-bottom: 1rem;
}