@import 'velo-variables';
@import '../navigationDrawer.variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

/* static width to allow for animation effect on collapse / expand */
$width: $navigation-list-width;

margin: 0.5rem 1rem;
display: flex;
flex-direction: row;
opacity: 1;
min-width: $width;
transition: $opening-transition-fadein;
transition-delay: $animation-duration;
color: velo-color('token-color-text-default');

&.highlight {
background-color: velo-color('token-color-system-warning-lighter');
font-weight: bold;
flex: 1;
align-self: center;
}

&.hidden {
opacity: 0;
transition: $opening-transition-fadein;
transition-delay: 0ms;
}
}