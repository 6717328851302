.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-right: 2.5rem;
display: flex;
flex-direction: column;
&:last-child {
margin-right: 0;
}
span:first-child {
flex-grow: 1;
}
}