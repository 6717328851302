@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

> div:first-child {
&:focus {
background-color: velo-color(
'token-color-brand-primary-background'
) !important;
}
&:before,
&:after {
opacity: 0;
}
}
}