.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding: 1rem 1rem 1rem 2.75rem;

&.compact {
padding-left: 0 !important;
padding-right: 0 !important;
}
}