@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

list-style: none;
padding: 0;
margin: 0;

@media (min-width: velo-breakpoint(XS)) {
text-align: center;
}
}