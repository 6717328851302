@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

/* Apply the theme colour directly to fix an Edge issue. */
/* https://github.com/material-components/material-components-web/issues/5044 */
&:global(.mdc-button--unelevated:not(:disabled)) {
background-color: velo-color('token-color-brand-primary');
}
}