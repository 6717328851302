.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 1.25rem;
transform: rotate(0deg);
transition: transform 250ms ease-in-out;
transform-origin: center center;

&.descending {
transform: rotate(180deg);
}

&.align-start,
&.align-middle {
margin-right: 0.5rem;
order: -1;
}

&.align-end {
margin-left: 0.5rem;
order: 0;
}

&.hidden {
opacity: 0;
}
}