@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@media (max-width: velo-breakpoint(XS)) {
margin: 0;
width: 100%;
}

&.alignEnd {
align-self: flex-end;
min-width: 15rem;

@media (max-width: velo-breakpoint(XS)) {
width: 100%;
}
}

&.alignInCardStepper {
align-self: flex-end;
width: 15rem;
margin-right: 1.5rem;

@media (max-width: velo-breakpoint(XS)) {
margin-right: initial;
margin: auto;
}
}
}