@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

background-color: velo-color('token-color-system-page-background');
&:global(.mdc-button:not(:disabled)) {
background-color: velo-color('token-color-system-page-background');
}
}