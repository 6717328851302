@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 100%;
margin-left: 1rem;
display: flex;

@media (max-width: velo-breakpoint(XS)) {
padding-left: 0;
margin-left: 0;
}

@media (min-width: velo-breakpoint(XS)) {
margin-top: 1rem;
margin-bottom: 1rem;
}

&.narrow {
margin-left: 0;
}
}