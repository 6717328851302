@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: inline-block;

:global(.mdc-form-field) {
color: velo-color('token-color-text-default');
font-family: var(--ds-typography-font-family);
}
}