@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

&.type-warning {
color: velo-color('token-color-system-warning-default');
}

&.type-success {
color: velo-color('token-color-system-success-default');
}

&.type-info {
color: velo-color('token-color-system-info-default');
}

&.type-error {
color: velo-color('token-color-system-error-default');
}

&.type-hibanainfo {
color: velo-color('token-color-text-default');
}

&.type-unknown {
color: velo-color('token-color-text-default');
}
}