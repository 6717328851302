.currentlyOperatingMessage {
  padding: 1.5rem 0;
}

.selectOperatingAsDialog {
  padding: 0 1rem 1.5rem;
}

.card {
  padding: 0;
}
