@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: fixed;
width: 100%;
height: 100%;
top: 0;
left: 0;
z-index: $velo-desktop-in-front-index-default - 1;
}