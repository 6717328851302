@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: none;

@media (min-width: velo-breakpoint(XS)) {
display: inline-block;
width: 100%;
overflow: hidden;
padding-right: 1rem;
/** Prevents size issue on list items creating "double rows" */
& > [class*='VeloTable-VeloTableCell'] {
display: block;
}
}
}