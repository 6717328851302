.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
justify-content: center;
text-align: center;
height: 100%;
align-items: center;
}