@import 'velo-variables';
@import '../OnboardingPage/variables';
@import '@material/theme/mixins';

.subtitle {
  @media (max-width: velo-breakpoint(XS)) {
    text-align: left;
  }

  &Disabled {
    color: velo-color('token-color-text-disabled');
  }
}

.divider {
  margin: 1.5rem 0;

  @media (max-width: velo-breakpoint(XS)) {
    margin: 1rem 0;
  }
}

.section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@mixin responsive-width {
  width: $button-width;

  @media (max-width: velo-breakpoint(XS)) {
    width: 100%;
  }
}

.textField {
  @include responsive-width();
}

.sendCodeButton {
  @include responsive-width();

  margin-top: 1.5rem;

  @media (max-width: velo-breakpoint(XS)) {
    margin-top: 1rem;
  }
}

.error {
  @include responsive-width();
}
