@import 'velo-variables';
@import '../ViewHeight/mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border-radius: 2px 2px 2px 2px;
box-shadow: 0 2px 4px 0 velo-color('token-color-system-shadow');
width: 29rem;
display: flex;

@media (max-width: velo-breakpoint(XS)) {
min-width: 100%;
@include vh(min-height, 100);
}
}