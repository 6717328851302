$animation-time: 150ms;

.enter {
  transform: translateY(-100%);
}

.enterActive {
  transform: translateY(0%);
  transition: transform $animation-time ease-in-out;
}

.exit {
  transform: translateY(0%);
}

.exitActive {
  transform: translateY(-100%);
  transition: transform $animation-time ease-in-out;
}
