.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 130px;
height: 100px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
text-align: center;
}