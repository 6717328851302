@import 'velo-variables';
@import '@design-system/tokens/typography/scss/mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
justify-content: space-between;
padding: 0.5rem 0.5rem 0.5rem 1rem;

@media (min-width: velo-breakpoint(XS)) {
/* Hide the header */
display: none;
}

:global(.mdc-button__label) {
@include ds-typography-ellipsis;
}
}