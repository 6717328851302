@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include velo-font-weight(medium);
width: 5rem;
margin: 1rem 0;
text-align: center;
background-image: linear-gradient(
velo-color('token-color-system-divider'),
velo-color('token-color-system-divider')
);
background-size: 100% 0.1rem;
background-repeat: no-repeat;
background-position: center;
}