@import 'velo-variables';
@import '@design-system/tokens/typography/scss/mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@mixin left-align-mobile() {
/* Left align columns on mobile */
@media (max-width: velo-breakpoint(XS)) {
text-align: left;
justify-content: flex-start;
}
}

@include ds-typography-ellipsis;

flex-grow: 1;
text-align: left;
width: 100%;
padding-right: 1rem;

/* No need for extra padding on mobile as cells are vertically stacked. */
@media (max-width: velo-breakpoint(XS)) {
padding-right: 0rem;

&.hiddenOnMobile {
display: none;
}
}

&.align-end {
@include left-align-mobile;

text-align: right;
justify-content: flex-end;
}

&.align-middle {
@include left-align-mobile;

text-align: center;
justify-content: center;
}

&.dateTimePadding {
@media (max-width: velo-breakpoint(XS)) {
padding-left: 4rem;
}
}

@media (min-width: velo-breakpoint(XS)) {
&.size-xsmall {
flex-basis: 25%;
}

&.size-small {
flex-basis: 50%;
}

&.size-large {
flex-basis: 150%;
}

&.size-xlarge {
flex-basis: 200%;
}
}
}