@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include from-breakpoint(XS) {
&.followsDivider {
padding: 0rem 1rem;
}
}
}