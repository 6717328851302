@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 100%;
display: flex;
justify-content: space-evenly;

@media (max-width: velo-breakpoint(XS)) {
flex-wrap: wrap;
}

&.wrap {
white-space: normal;
}
}