@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@media (max-width: velo-breakpoint(XS)) {
left: 0;
top: 0;
height: 100%;
width: 100%;
display: flex;
align-items: center;
padding: 0;
}
}