.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
flex-basis: 100%;

&.icon {
padding-left: 1rem;
}
}