.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
&.column {
flex-direction: column;
}

> :first-child {
order: 1;
}

&.justify {
justify-content: space-between;
}
}