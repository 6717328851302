@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: none;

@include from-breakpoint(XS) {
display: flex;
align-items: center;
}
}