@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 48%;
margin: 1%;

@media (max-width: velo-breakpoint(XS)) {
margin: 0.5rem 0;
width: 100%;
}
}