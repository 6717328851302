@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

background-color: velo-color('token-color-system-error-lighter');
padding: 1rem;
margin-bottom: 1.5rem;
border-radius: 4px;
width: 100%;
text-align: center;
box-sizing: border-box;
}