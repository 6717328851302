@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

height: 3rem;
display: flex;
align-items: center;
justify-content: flex-start;
padding: 0 1rem;
overflow: hidden;
}