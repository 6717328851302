@import 'velo-variables';
@import 'velo-mixins';

$daypicker-size: 296px;
$daypicker-height: $daypicker-size;
$daypicker-width: $daypicker-size;
$daypicker-font-size: 14px;
$daypicker-font-size-mobile: 18px;
$daypicker-weekday-color: velo-color('token-color-text-muted');
$daypicker-base-color: velo-color('token-color-text-muted');
$daypicker-base-color-2: velo-color('token-color-system-page-surface');
$daypicker-disabled-day: velo-color('token-color-text-disabled');
$daypicker-primary-color: velo-color('token-color-brand-primary');
$daypicker-border-color: velo-color('token-color-border-default');

.DayPicker {
  display: inline-block;
  width: $daypicker-width;
  height: $daypicker-height;
  padding: 30px 0px;
  font-family: var(--ds-typography-font-family);
  font-size: $daypicker-font-size;

  @media (max-width: velo-breakpoint(XS)) {
    width: 100%;
    font-size: $daypicker-font-size-mobile;
  }

  &-wrapper {
    position: relative;
    flex-direction: row;
    outline: none;
    user-select: none;
  }

  &-Month {
    border-spacing: 0;
    border-collapse: collapse;
    user-select: none;
    width: 100%;
  }

  &-Months {
    color: $daypicker-base-color;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-Caption {
    text-align: left;
    font-size: $daypicker-font-size;
    margin-left: 21px;
    margin-bottom: 1rem;

    @media (max-width: velo-breakpoint(XS)) {
      font-size: $daypicker-font-size-mobile;
      margin-left: 1rem;
    }

    > div {
      @include velo-font-weight(bold);
    }
  }

  &-Weekdays {
    height: auto;
    border-bottom: 1px solid $daypicker-border-color;
  }

  &-WeekdaysRow,
  &-Week {
    grid-template-columns: repeat(7, 1fr);
    display: grid;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;

    @media (max-width: velo-breakpoint(XS)) {
      padding: 0 8px;
    }
  }

  &-Weekday {
    color: $daypicker-weekday-color;
    text-align: center;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;

    abbr[title] {
      border-bottom: none;
      text-decoration: none;
    }
  }

  &-Body {
    margin-top: 10px;
    outline: none;

    @media (max-width: velo-breakpoint(XS)) {
      margin-top: 0;
    }
  }

  &-Week {
    &Number {
      border-right: 1px solid $daypicker-border-color;
      color: $daypicker-base-color;
      text-align: right;
      font-size: 0.75rem;
      cursor: pointer;
    }
    @media (max-width: velo-breakpoint(XS)) {
      margin-top: 1rem;
    }
  }

  &-Day {
    border-radius: 50%;
    margin: 0.15rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    color: $daypicker-base-color;

    &--sunday {
      background-color: $daypicker-base-color-2;
    }

    &--sunday:not(.DayPicker-Day--today) {
      color: $daypicker-disabled-day;
    }

    &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      position: relative;

      background-color: $daypicker-primary-color;
      color: $daypicker-base-color-2;
    }

    &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
      background-color: $daypicker-primary-color;
    }

    &--outside {
      color: $daypicker-base-color;
      cursor: default;
    }

    &--disabled {
      color: $daypicker-disabled-day;
      cursor: default;
    }

    &--today:not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
      border: 1px solid $daypicker-border-color;
    }
  }

  &-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: $daypicker-primary-color;
    font-size: 0.875rem;
    cursor: pointer;
  }

  &-Weekday,
  &-Day {
    &::before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 0;
      padding-bottom: 100%;
    }
  }

  &-NavButton {
    position: absolute;
    outline: none;
    top: -0.3rem;
    right: 1.5rem;
    left: auto;
    display: inline-block;
    margin-top: 2px;
    width: 1.25rem;
    height: 1.25rem;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: $daypicker-base-color;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    &--prev {
      margin-right: 2.5rem;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
    }

    &--next {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
    }

    &--interactionDisabled {
      display: none;
    }

    @media (max-width: velo-breakpoint(XS)) {
      top: -0.8rem;
      right: 1rem;
      width: 44px;
      height: 44px;
      padding: 6px;
      box-sizing: border-box;
      background-size: 33%;

      &--prev {
        margin-right: 2.5rem;
      }

      &--prev,
      &--next {
        padding: 0.4rem;
      }
    }
  }
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: $daypicker-base-color-2;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: $daypicker-primary-color;
  @include velo-font-weight(bold);
}
