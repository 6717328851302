@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

z-index: $velo-desktop-in-front-index-default;
@include smooth-scrolling;

/* Full-screen on mobile */
@media (max-width: velo-breakpoint(XS)) {
position: fixed;
top: 0 !important;
left: 0 !important;
height: 100%;
width: 100%;
max-width: 100%;
z-index: $velo-over-all-default;
border-radius: 0;
max-height: inherit;
}
}