@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding: 0rem 0rem 1rem;

@media (min-width: velo-breakpoint(XS)) {
background-color: velo-color('token-color-system-page-background');
}
}