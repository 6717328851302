.cls1 { /*!*/ }
.cls2 {
composes: cls1;

height: 56px;
margin-bottom: 30px;
margin-top: 6px;

&:last-child {
margin-top: 13px;
}
}