@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

:global(.mdc-dialog__title) {
text-align: left;
}

:global(.mdc-dialog__surface) {
@media (min-width: velo-breakpoint(XS)) {
/* On desktop fix the width of the dialogs to 400px */
width: 25rem;
max-width: 25rem;
min-width: 25rem;
}
}
}