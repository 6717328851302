.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border-top-left-radius: 0;
border-bottom-left-radius: 0;
min-width: 30px;
padding: 0px;

:global(.mdc-button__icon) {
margin-left: 0;
margin-right: 0;
}
}