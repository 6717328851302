.cls1 { /*!*/ }
.cls2 {
composes: cls1;

&:global(.ds-icon) {
font-size: 24px;
line-height: 24px;
}

&:global(.mdc-list-item__meta:not(.ds-icon)) {
font-family: var(--ds-typography-font-family);
}
}