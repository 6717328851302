@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding: 10px 20px 5px 20px;
display: flex;
flex-direction: column;
align-items: flex-end;

@media (max-width: velo-breakpoint(XS)) {
flex-direction: row;
align-items: center;
}
}