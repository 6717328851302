@import 'velo-variables';
@import 'velo-mixins';
@import './navigationDrawer.variables';

$intent-color: velo-color('token-color-text-link-bg-intent');
$active-color: velo-color('token-color-brand-primary-background');
$active-hover-color: $intent-color;

$hover-opacity: 0.12;
$intent-selected-opacity: 0.24;
$intent-hover-opacity: 0.18;
$intent-selected-hover-opacity: 0.32;

.fadeoutOnCollapse,
.fadeinOnExpand {
  min-width: $navigation-label-min-width;
  display: inline-block;
  animation-duration: $animation-duration;
  animation-fill-mode: both;
}
.fadeoutOnCollapse {
  animation-name: fadeOut;
}
.fadeinOnExpand {
  animation-name: fadeIn;
}

:global(:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item) {
  &.flyoutMenu,
  &.subItem.activated,
  &.item {
    &:hover {
      &:after,
      &:before {
        background-color: $active-hover-color !important;
        opacity: $hover-opacity !important;
      }
    }
    &:after,
    &:before {
      mix-blend-mode: multiply;
      background-color: $intent-color;
    }
    &.subItem.activated {
      background-color: $active-color !important;
      &:hover {
        &:after,
        &:before {
          background-color: $active-hover-color !important;
          opacity: 0.24 !important;
        }
      }
      &:after,
      &:before {
        background-color: transparent !important;
        opacity: 1 !important;
      }
    }
  }

  &.subItem {
    &:after,
    &:before {
      background-color: $intent-color;
    }
  }
}

:global(:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item) {
  &.collapsed {
    &:hover {
      &:after,
      &:before {
        background-color: transparent !important;
        opacity: 0 !important;
      }
    }
  }
}

:global(:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused) {
  &:after,
  &:before {
    mix-blend-mode: multiply;
    opacity: $intent-selected-opacity !important;
  }

  &:hover:before {
    opacity: $intent-selected-hover-opacity !important;
  }

  &.subItem {
    &:after,
    &:before {
      opacity: $intent-hover-opacity;
    }
  }
}

%item {
  height: 3.5rem;
  transition: color $animation-duration linear;
  padding: 0 0.8rem;
  text-decoration: inherit;
  text-transform: inherit;
  user-select: none;
  flex: auto;
  align-items: center;

  * {
    user-select: none;
  }
  &:hover {
    cursor: pointer;
    &:before,
    &:after {
      opacity: $hover-opacity !important;
    }
  }

  &:global(.mdc-list-item--activated) {
    &:before,
    &:after {
      background-color: transparent !important;
    }
  }
  i {
    padding: 12px;
    margin-right: 0px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:global(.mdc-list-item__graphic) {
      color: velo-color('token-color-text-default');
    }
  }
  .label {
    min-width: $navigation-label-min-width;
    color: inherit;
  }
  .rightSideIcon {
    opacity: 1;
    transition: opacity $animation-duration linear;
    color: velo-color('token-color-text-default') !important;
    padding-right: 0;
    padding-left: 0;
    position: absolute;
    right: 15px;
    display: flex;
    align-items: flex-end;
    &.right {
      padding-bottom: 0;
    }
  }

  &.noicon {
    .label {
      animation-name: fadeIn;
    }
  }

  &.legalLinksPrimaryItem {
    &:first-child {
      padding-top: 5px;
      padding-bottom: 5px;
      height: 36px !important;
      &.collapsed {
        opacity: 1;
      }
    }
    &.collapsed {
      &:focus {
        background-color: transparent !important;
        &:before,
        &:after {
          opacity: 0 !important;
          background-color: transparent !important;
        }
      }
    }
  }
  &.activated {
    &.subItem {
      opacity: $intent-hover-opacity;
    }
  }

  &.parent {
    &.activated {
      i:last-child {
        transform: rotate(180deg);
        transform-origin: center center;
        justify-content: center;
      }
    }
  }

  &:global(.mdc-list-item--activated) {
    background-color: $active-color !important;
    color: inherit;

    :global(.ds-icon) {
      color: velo-color('token-color-brand-primary');
    }
    &:hover {
      cursor: pointer;
    }
  }

  &:nth-child(1) {
    margin-top: 0;
  }

  &.noicon {
    height: 38px !important;
    padding-left: 3.9rem;
    transition: $opening-animation;
    &.parent {
      height: 58px !important;
    }
  }

  &.noicon {
    opacity: 1;
  }

  &.collapsed {
    margin: 0 0.1rem 0 0.8rem;
    padding: 0;
    color: transparent;
    user-select: none;
    &:global(.mdc-list-item--activated) {
      background-color: transparent !important;
    }

    &:focus {
      background-color: transparent !important;
      &:before,
      &:after {
        opacity: 0 !important;
        background-color: transparent !important;
      }
    }

    .indicator {
      left: 28px;
    }

    &.noicon {
      .label {
        animation-name: fadeOut;
      }
    }

    &.noicon {
      opacity: 0;
      &:hover {
        cursor: default;
      }
    }

    .showInCollapsed {
      position: relative;
      left: -18px;
      transform: rotate(-90deg) scale(0.9);
      opacity: 0;
      display: none;
    }
    &:hover {
      .rightSideIcon {
        position: relative;
        left: -3px;
        top: 1px;
      }
      .showInCollapsed {
        opacity: 1;
        display: inline;
        background-color: transparent !important;
      }
    }
    &:after,
    &:before {
      opacity: 0 !important; //override mdc default opacity
    }

    .rightSideIcon {
      opacity: 0;
      transition: $opening-animation;
    }
    &:global(.mdc-list-item--activated) {
      .rightSideIcon.more {
        color: velo-color('token-color-brand-primary') !important;
      }
    }

    &:focus,
    &:hover {
      .rightSideIcon.more {
        opacity: 1;
        color: velo-color('token-color-text-muted') !important;
      }

      i:not(.rightSideIcon) {
        background-color: velo-color(
          'token-color-brand-primary-background'
        ) !important;
        border-radius: 50%;
      }
    }
  }
}
