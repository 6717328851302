.cls1 { /*!*/ }
.cls2 {
composes: cls1;

:global {
.mdc-dialog__surface {
position: relative;
}

.mdc-dialog__actions {
border: none;
}

@media (min-width: 711px) {
.mdc-dialog__surface {
min-width: 711px;
}
}

@media (max-width: 711px) {
.mdc-dialog__surface {
border-radius: 0;
max-width: 100%;
max-height: 100%;
}
}
}
}