.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding: 0;

:global {
.mdc-layout-grid__inner {
grid-auto-rows: 1fr;
}
}
}