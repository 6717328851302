@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: row;
align-items: flex-start;
color: inherit;
flex: 2;
padding: 1rem;

span + button {
padding-left: 0.25rem;
@media (max-width: velo-breakpoint(XS)) {
text-align: left;
padding-left: 0;
display: block;
}
}
}