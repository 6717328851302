@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
background-color: velo-color('token-color-system-page-background');
padding: 1rem;

&.form {
padding-left: 1.5rem;
padding-right: 0.5rem;
}
}