@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-family: var(--ds-typography-font-family);

/* https://github.com/material-components/material-components-web/issues/5044 */
:global(.mdc-tab__ripple::before),
:global(.mdc-tab__ripple::after) {
background-color: velo-color('token-color-brand-primary');
}
}