@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
background-color: velo-color('token-color-system-page-background') !important;
height: 100%;
width: 100%;
z-index: $velo-over-all-default;

transform: translateX(100%);
&.open {
transform: translateX(0%);
}
transition: transform 250ms ease-in-out;
}