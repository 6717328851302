@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 100%;
margin: 1.5rem 0;

@media (max-width: velo-breakpoint(XS)) {
margin: 1rem 0;
}
}