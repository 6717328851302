.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 25%;
height: 0.875rem;

&.secondary {
height: 0.75rem;
}
}