@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

cursor: pointer;
border-bottom: 2px solid velo-color('token-color-system-divider');

&:last-child {
border: none;
}
}