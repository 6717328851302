@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border-bottom: 0;
margin-bottom: 1rem;
&.underline {
border-bottom: 1px solid velo-color('token-color-system-divider');
}
&.overline {
border-bottom: none;
margin-bottom: 0;
padding-top: 1.5rem;
padding-bottom: 0.5rem;
border-top: 1px solid velo-color('token-color-system-divider');
}
&.tail {
&:first-child {
padding-top: 0;
border-top: none;
}
}
&.compact {
margin-bottom: 0;
}
}