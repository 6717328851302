@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-right: 2rem;

@media (max-width: 320px) {
margin-right: 1.5rem;
}

&:last-child {
margin-right: 0;
}

@media (max-width: velo-breakpoint(XS)) {
text-align: center;
}
}