@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

background-color: velo-color('token-color-system-info-lighter');
padding-top: 0.2rem;
height: 3.2rem;
width: inherit;
}