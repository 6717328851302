@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

&.disabled {


color: velo-color('token-color-text-disabled');
}
}