.cls1 { /*!*/ }
.cls2 {
composes: cls1;

$padding: 120px;
$other-padding: 72px;

overflow: hidden;

&:global(.mdc-text-field--with-leading-icon .mdc-text-field__input) {
padding-left: $padding;
}

&:global(.mdc-text-field--with-leading-icon
.mdc-floating-label:not(.mdc-floating-label--float-above)) {
left: $padding;
}

&.other {
&:global(.mdc-text-field--with-leading-icon .mdc-text-field__input) {
padding-left: $other-padding;
}

&:global(.mdc-text-field--with-leading-icon
.mdc-floating-label:not(.mdc-floating-label--float-above)) {
left: $other-padding;
}
}

&.floatedLabel {
overflow: visible;
}
}