.cls1 { /*!*/ }
.cls2 {
composes: cls1;

order: 2;
&.start {
order: 0;
&.gridGutter {
margin-bottom: 16px;
}
}

&:not(.start) {
&.gridGutter {
margin-top: 16px;
}
}
}