@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: row;
justify-content: space-between;

&.narrow {
flex-direction: column;
}

@media (max-width: velo-breakpoint(XS)) {
flex-direction: column;
}

:global(.mdc-text-field-helper-text) {
margin-bottom: 1.5rem;
}
}