@import '@design-system/tokens/typography/scss/mixins';

/* Ensures wrapping component do not override the alignment of a formField */
.helpText {
  @include ds-typography-ellipsis;

  text-align: left;
}

.currency {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
