@import 'velo-variables';
@import '../OnboardingPage/variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin: auto;


width: $button-width;

@media (max-width: velo-breakpoint(XS)) {
width: 100%;
}
}