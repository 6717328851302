@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
margin-top: 0.25rem;
color: velo-color('token-color-system-error-default');
}