@import '../OnboardingPage/variables';
.section {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.form {
  max-width: $button-width;
}
