@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

line-height: normal;
text-transform: none;
letter-spacing: normal;
font-weight: normal;

:global(.mdc-button__label) {
color: velo-color('token-color-text-default');
}

&:disabled {
:global(.mdc-button__label) {
color: velo-color('token-color-text-disabled');
}
}
}