@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

background-color: velo-color('token-color-system-page-surface');
border: 0.04rem dashed velo-color('token-color-text-muted');
padding: 1rem;
display: flex;
flex-direction: column;
align-items: center;

@media (min-width: velo-breakpoint(S)) {
padding: 2rem;
}
}