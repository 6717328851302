@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

$iconSize: 20px;
position: absolute;
height: 1px;
background-color: velo-color('token-color-system-divider');
top: calc(50%);
left: calc(50% + #{$iconSize});
}