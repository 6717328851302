@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

background-color: velo-color('token-color-system-page-background');

&.rounded {
border-radius: 0.25rem;
}

&.status-info {
background-color: velo-color('token-color-system-info-lighter');
}

&.status-warning {
background-color: velo-color('token-color-system-warning-lighter');
}

&.status-success {
background-color: velo-color('token-color-system-success-lighter');
}

&.status-unknown {
background-color: velo-color('token-color-system-unknown');
}

&.status-error {
background-color: velo-color('token-color-system-error-lighter');
}
}