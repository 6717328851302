@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border-bottom-left-radius: inherit;
border-bottom-right-radius: inherit;

&.scrollable {
/* Make the body scrollable on mobile. */
@media (max-width: velo-breakpoint(XS)) {
@include smooth-scrolling;

height: auto;
}
}
}