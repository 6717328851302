@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: space-between;
align-content: center;
margin: 0 1rem;

&.disableMargin {
margin: 0;
}

@media (max-width: velo-breakpoint(XS)) {
/* header not needed on mobile as content page title now displayed in TopAppBar */
display: none;
}
}