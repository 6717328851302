.cls1 { /*!*/ }
.cls2 {
composes: cls1;

&.compact {
padding: 0;

:global(.mdc-layout-grid__inner) {
margin: calc(16px / 2 * -1);

@supports (display: grid) {
margin: 0;
grid-gap: 16px;
}
}
}
}