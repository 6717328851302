@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include velo-font-weight(bold);

color: velo-color('token-color-text-inverse');
text-decoration: none;
background: transparent;
padding: 0;
border: 0;
font-size: 1rem;

&:hover,
&:focus {
text-decoration: none;
cursor: pointer;
}
}