@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

$iconSize: 20px;
display: flex;
flex-direction: column;
align-items: center;
white-space: nowrap;
position: relative;
&::before {
font-family: 'DsIconFont';
font-size: $iconSize;
width: $iconSize;
height: $iconSize;
content: '\f10e';
color: velo-color('token-color-brand-primary');
margin: 8px 0;
order: 1;
}
}