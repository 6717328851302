@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

&.fixed {
position: fixed;
z-index: $velo-over-all-default + 1;
}
}