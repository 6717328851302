.cls1 { /*!*/ }
.cls2 {
composes: cls1;

$text-field-width: 20rem;
$text-field-width-compact: 7rem;

position: relative;
min-width: $text-field-width;

&.compactWidth {
min-width: $text-field-width-compact;
}
}