@import 'velo-variables';
@import 'velo-mixins';
@import '@material/ripple/common';
@import '@design-system/tokens/typography/scss/mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

$bottom-border: 0.25rem;
$margin-default: 0rem;

@include ds-typography-ellipsis;

cursor: pointer;
height: auto;
padding-top: 1rem;
padding-bottom: 1rem;
background-color: velo-color('token-color-system-page-background');
margin-top: 0.25rem;
margin-bottom: 0.25rem;

&:hover {
background-color: rgba(#0077ba, 0.2);
}

&.static {
@include non-interactive;

cursor: default !important;
}

&.border {
margin-bottom: $margin-default;
margin-top: $margin-default;
border-bottom: $bottom-border solid
velo-color('token-color-system-page-surface');
}

&.header {
@include non-interactive;

cursor: default;
background-color: transparent;

/* Hide the header on mobile */
@media (max-width: velo-breakpoint(XS)) {
display: none;
}
}
}