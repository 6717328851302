@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@media (min-width: velo-breakpoint(XS)) {
padding: 1.5rem 1.5rem 0;
display: flex;
}
flex-direction: column;
width: 100%;

&.compact {
padding: 0;
}
}