@import 'velo-variables';
@import 'velo-mixins';
@import '../navigationDrawer.variables';

.mobile {
  &.drawer {
    user-select: none;
    z-index: $velo-over-all-default;
  }
}

.scrollContent {
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  @include smooth-scrolling;
  overflow-x: hidden;
}

.drawer {
  background-color: velo-color('token-color-system-page-background');

  display: flex;
  width: $navigation-width-expanded;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid velo-color('token-color-system-divider');
  left: 0;
  right: auto;
  position: fixed;
  overflow: hidden;
  z-index: $velo-over-all-default;
  &.collapsed {
    width: $navigation-width-collapsed;
    transition: $opening-transition-desktop;
  }
}
