.cls1 { /*!*/ }
.cls2 {
composes: cls1;

height: 56px;
width: 56px;
border-radius: 28px;

&.extended {
width: 220px;
}
}