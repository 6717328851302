.payeeDisabledStatus {
  flex-basis: 45%;
}

.onboardedStatus {
  flex-basis: 70%;
}

.watchlistStatus {
  flex-basis: 80%;
}

.dateAdded {
  flex-basis: 60%;
}
