@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 1.2rem;
@include velo-font-weight(bold);

@include from-breakpoint(XS) {
margin-top: 1rem;
}

&.error {
color: velo-color('token-color-system-error-default');
}
}