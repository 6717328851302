@import 'velo-variables';
@import '../navigationDrawer.variables.scss';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: $navigation-list-width;
z-index: $velo-full-screen-index-default;

:global(.mdc-list) {
padding: 0;
}
}