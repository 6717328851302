$animationTimeMs: 300ms;

.enter {
  max-height: 0px;
}

.enterActive {
  max-height: 100vh;
  transition: max-height $animationTimeMs ease-in-out;
}

.exit {
  max-height: 100vh;
}

.exitActive {
  max-height: 0px;
  transition: max-height $animationTimeMs ease-in-out;
}
