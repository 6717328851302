@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@mixin bar-status($width, $color) {
background-color: $color;
width: $width;
@if ($width == 100%) {
border-radius: 1.25rem;
} @else {
border-radius: 1.25rem 0 0 1.25rem;
}
}

min-height: 0.3125rem;
margin: 0;
line-height: 0.3125rem;

&.score-0,
&.score-1 {
/* Very weak, weak */
@include bar-status(20%, velo-color('token-color-system-error-default'));
}

&.score-2 {
/* Medium */
@include bar-status(60%, velo-color('token-color-system-warning-default'));
}

&.score-3 {
/* Strong */
@include bar-status(80%, velo-color('token-color-system-success-default'));
}

&.score-4 {
/* Very strong */
@include bar-status(100%, velo-color('token-color-system-success-default'));
}

&.loading {
border-radius: 1.25rem;
}
}