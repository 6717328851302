.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-family: var(--ds-typography-font-family);
line-height: 1.5; /** fixes icon alignment (was 2.25rem) */

&.fullWidth {
width: 100%;
}
}