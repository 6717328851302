@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

:global .mdc-text-field__icon {
outline: none;
}

&.selected {
:global .mdc-text-field__icon {
color: velo-color('token-color-brand-primary');
}
}
}