.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding-top: 2rem;
height: calc(100% - 7rem);

&.disabled {
opacity: 0.4;
}

&.enabling {
opacity: 0.6;
}
}