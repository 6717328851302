@import 'velo-variables';
@import '@material/elevation/mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include mdc-elevation(24);

position: fixed;
left: 0;
right: 0;
bottom: 0;
border: none;
max-height: 100%;
z-index: $velo-over-all-default;
}