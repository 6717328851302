@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include velo-font-weight(medium);

word-wrap: break-word;
white-space: normal;
text-align: center;
display: flex;
flex-direction: column;

@media (min-width: velo-breakpoint(XS)) {
display: none;
}
}