@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

background-color: velo-color('token-color-system-warning-lighter');
padding: 0.1rem 1rem 1rem;

&.edit {
margin-top: 1rem;
}

&.backoffice {
padding-bottom: 0.1rem;
}
}