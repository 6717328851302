@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-top: 1rem;
margin-bottom: 1rem;

@include from-breakpoint(XS) {
margin-top: 2rem;
margin-bottom: 1rem;
}
}