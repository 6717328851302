@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@media (max-width: velo-breakpoint(XS)) {
position: absolute;
left: 0;
top: 0;
height: 100%;
width: 4rem;
display: flex;
justify-content: center;
align-items: center;
padding: 0;
}
}