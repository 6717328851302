@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include icon-size(4rem);

color: velo-color('token-color-system-error-default');
}