@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

:global(.mdc-dialog__surface) {
/* This overrides the width of the dialog on desktop to match the UX design */
@media (min-width: 592px) {
max-width: velo-breakpoint(XS);
}
}
}