@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
background-color: velo-color('token-color-system-error-lighter');
border-bottom-left-radius: inherit;
border-bottom-right-radius: inherit;

min-height: 2rem;
padding-left: 1rem;
padding-right: 1rem;
}