@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

&:global(.mdc-card) {
padding: 0;
margin-top: 0;

@include from-breakpoint(XS) {
&:not(.slimline) {
max-width: 74rem;
}

/** The divider needs to fullBleed on the card so remove the padding */
&:not(.hasDivider) {
padding: 0rem 1rem;
}
}
}

&.fixedBottomPaginationOffset {
height: calc(100% - #{$mobile-fixed-bottom-pagination-height});
}
}