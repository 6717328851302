@-webkit-keyframes fadeOut {
  0% {
    color: #000;
  }
  100% {
    color: transparent;
  }
}
@keyframes fadeOut {
  0% {
    color: #000;
  }
  100% {
    color: transparent;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    color: transparent;
  }
  100% {
    color: #000;
  }
}
@keyframes fadeIn {
  0% {
    color: transparent;
  }
  100% {
    color: #000;
  }
}
