@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
margin: 3.5rem 2rem 3.5rem 2rem;
flex-grow: 1;

@media (max-width: velo-breakpoint(XS)) {
margin: 2rem 1rem 1rem 1rem;
}

&.leftAlign {
text-align: left;
}

&.compact {
margin: 3.5rem 0.5rem 3.5rem 0.5rem;
}
}