@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border-left: 1px solid velo-color('token-color-system-divider');
margin-left: 0.75rem;
padding: 0.75rem 1.5rem;

&.form {
padding-right: 1rem;
}
}