@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

color: velo-color('token-color-text-default');
border-top: 1px solid velo-color('token-color-system-divider');
border-bottom: 1px solid velo-color('token-color-system-divider');
padding: 0.8rem 0 0.8rem 0;
}