.cls1 { /*!*/ }
.cls2 {
composes: cls1;

&.sortable {
display: flex;
align-items: center;
cursor: pointer;

&:hover {
:global(.velo-table-hidden-sort-arrow) {
opacity: 0.5;
}
}
}
}