@import '@material/elevation/mixins';
@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: flex-end;
align-items: center;
height: 3.75rem;

@media (max-width: velo-breakpoint(XS)) {
@include mdc-elevation(4);
height: $mobile-fixed-bottom-pagination-height;
justify-content: space-evenly;
background-color: velo-color('token-color-system-page-background');
position: fixed;
left: 0;
right: 0;
bottom: 0;
}
}