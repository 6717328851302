@import 'velo-variables';
@import '@material/fab/mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-family: var(--ds-typography-font-family);

/* Apply the theme colour directly to fix an Edge issue. */
/* https://github.com/material-components/material-components-web/issues/5044 */
background-color: velo-color('token-color-brand-primary');

&:global(.mdc-fab--extended) {
box-shadow: none;
/**
* Required for Safari as the built-in user-agent style
* sheet does not reset all margins.
*
* Without this the gap inside a VeloSplitFab is too wide.
*/
margin: 0;
}

&.disabled {
background-color: velo-color('token-color-text-disabled');
pointer-events: none;
}
}