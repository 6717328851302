@import '@material/theme/mixins';
@import '@material/textfield/variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

$mdc-text-field-helper-text-height: 19px;
height: $mdc-text-field-height;
margin-bottom: $mdc-text-field-helper-text-height;
}