@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@media (min-width: velo-breakpoint(XS)) {
display: none;
text-align: left;
}
}