@import 'velo-variables';
@import '@material/elevation/mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include mdc-elevation(1);

max-width: inherit;
z-index: $velo-over-all-default;
width: 100%;

&.adjust {
/* Adjust to take text filed helper text into account */
margin-top: -1.1875rem;
}
}