@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
padding: 0.8rem;
justify-content: space-between;
flex-direction: column;
gap: 1rem;
align-items: center;
margin-bottom: 1rem;

@media (min-width: velo-breakpoint(XS)) {
flex-direction: row;
gap: 0;
}
}