@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

background-color: velo-color('token-color-brand-primary');
display: flex;
justify-content: center;
width: 100%;
}