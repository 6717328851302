@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

max-width: 74rem;
margin: 2.5rem auto;
padding: 0rem 1rem;

@media (max-width: velo-breakpoint(XS)) {
padding: 0;
margin-top: 0;
padding-bottom: 4rem;
width: 100%;
max-width: 100%;
}
}