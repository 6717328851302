@keyframes skeleton-bg {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

$skeleton-color: velo-color(token-color-system-skeleton-background);

@mixin skeleton-background() {
  background-color: $skeleton-color;
  background-image: velo-color(token-color-system-skeleton-gradient);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  animation: skeleton-bg 1.2s ease-in-out infinite;
}
