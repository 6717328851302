@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include velo-font-weight(medium);

word-wrap: break-word;
white-space: normal;
text-align: center;
display: flex;
flex-direction: row;

@media (min-width: velo-breakpoint(XS)) {
display: none;
}

@media (max-width: velo-breakpoint(XS)) {
position: absolute;
left: 0;
top: 0;
height: 100%;
width: 4rem;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
padding: 0;
}
}