@import 'velo-variables';
@import 'velo-mixins';
@import '../ViewHeight/mixins';
@import 'navigationDrawer.variables';

.appRoot {
  display: flex;
  flex-direction: column;
  @include vh(min-height, 100);
}

.content {
  margin-left: $navigation-width-expanded;
  transition: margin-left $content-animation-duration ease-in;
  display: flex;
  flex: 1 1;
  position: relative;
  &.contentCollapsed {
    margin-left: $navigation-width-collapsed;
  }
  &.contentMobile {
    margin-left: 0 !important;
  }
}

.pagebody {
  // TODO: Decide if it is possible to implement the fixed message fixed to the bottom of the page with flex.
  // This may not be possible given how it effects all existing pages
  // Also is it necessary as the message flows into the page when the list grows (4/5 items on mobile etc) any way
  // This broken some of the layouts.
  // display: flex;
  width: 100%;

  .mobile & {
    padding: 0.5rem 0;
  }
}

.footergroup {
  margin-top: 6px;
}

.collapsed {
  .footergroup {
    margin-top: 0;
  }
}
.overrideTooltip {
  & + :global(.__react_component_tooltip) {
    display: none;
  }
}
:export {
  tabletBreakpoint: velo-breakpoint(S);
}
