.cls1 { /*!*/ }
.cls2 {
composes: cls1;

&.spaceEvenly {
display: flex;
justify-content: space-between;

&:global(.mdc-form-field > label) {
margin-right: inherit;
}

&:global(.mdc-form-field--align-end > label) {
margin-left: inherit;
}
}
}