.cls1 { /*!*/ }
.cls2 {
composes: cls1;

&.largeWidth {
:global {
@media (min-width: 592px) {
.mdc-dialog__surface {
max-width: 620px;
}
}
}
}
}