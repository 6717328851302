@import 'velo-variables';
@import '../navigationDrawer.variables';
@import '../navigationDrawerItem';

.indicator {
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  border-radius: 15px;
  border: 1px solid velo-color('token-color-system-page-background');
  left: 41px;
  top: 35px;

  .payments & {
    top: 30px;
  }
  &.warning {
    background: velo-color('token-color-system-warning-default');
  }
  &.success {
    background: velo-color('token-color-system-success-default');
  }
  &.unknown {
    background: velo-color('token-color-system-unknown');
  }
  &.error {
    background: velo-color('token-color-system-error-default');
  }
}

.item {
  @extend %item;
  button {
    padding: 0 2.5rem 0 1rem;
  }

  i {
    padding: 15px;
    margin-right: 0;
    outline: none;
    width: 20px;
    height: 20px;
    &:global(.mdc-list-item__graphic) {
      color: velo-color('token-color-text-default');
    }
  }
  span {
    flex: auto;
  }

  &:global(.mdc-list-item--activated) {
    :global(.ds-icon) {
      color: velo-color('token-color-brand-primary');
    }
  }

  :global(.mdc-list-item__meta.ds-icon) {
    font-size: 24px;
    letter-spacing: normal;
  }
}
