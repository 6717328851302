@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

color: velo-color('token-color-text-muted');
font-size: 1.25rem !important;
width: 1.25rem !important;
height: 1.25rem !important;
}