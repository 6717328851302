@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

background-color: velo-color('token-color-system-error-lighter');
border-radius: 4px;
flex-basis: 100%;
padding: 1rem;
text-align: center;
}