.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: row;
align-items: flex-start;
padding: 1rem 0 1rem;

i {
font-size: 1.4rem;
}
}