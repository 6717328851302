@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include smooth-scrolling;

overflow: auto;
padding: 1rem 1rem 0 1rem;
height: 100%;

&.compact {
padding: 0;
}
}