@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include skeleton-background();
border-radius: 4px;
display: inline-block;
line-height: 1;
width: 100%;
margin: 0.25rem 0;

&:before {
/**
* A zero-width space to ensure the skeleton height
* matches the font correctly and avoid some potential
* alignment issues.
*/
content: '\200B';
}
}