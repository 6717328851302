@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

min-height: 5rem;

@include from-breakpoint(XS) {
width: 30rem;
}
}