@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding: 0.5rem 2rem;
display: flex;
flex-direction: column;
justify-content: center;
background: velo-color('token-color-system-page-surface');

@media (max-width: velo-breakpoint(XS)) {
padding: 0.5rem 1rem;
}
}