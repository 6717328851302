@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

height: 0;
margin: 0;
border: none;
border-bottom-width: 1px;
border-bottom-style: solid;
border-bottom-color: velo-color('token-color-system-divider');

&.padded {
margin: 1rem 0;
}

&.vertical {
margin: 0;
height: 100%;
max-height: 100vh;
border-bottom: none;
display: inline-block;
border-left-width: 1px;
border-left-style: solid;
border-left-color: velo-color('token-color-system-divider');
}
}