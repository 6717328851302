@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@media (min-width: velo-breakpoint(XS)) {
margin-left: 1rem;
margin-right: 0.5rem;
}
}