@import 'velo-variables';
@import '../navigationDrawer.variables';

.fixedAdjust {
  padding-top: $topAppBarHeight;
}

.navigationTopAppBar {
  .logo {
    padding-left: 0.3rem;
    opacity: 1;
    transition: opacity $animation-duration ease-in-out;
    transition-delay: 300ms;
    &.hidden {
      opacity: 0;
      transition: opacity 200ms ease-in-out;
    }
  }

  &.content {
    & + .adjust {
      padding-top: 65px;
    }
    header {
      background-color: velo-color('token-color-system-page-surface');
      width: 100%;
      .open {
        width: $navigation-width-expanded;
        background-color: velo-color('token-color-system-page-background');
      }
    }
  }
  &.menu {
    :global(.mdc-top-app-bar--fixed) {
      position: relative;
    }
    &.open {
      header {
        width: $navigation-width-expanded;
      }
    }
    header {
      width: $navigation-width-collapsed;
      transition: $opening-transition-desktop;
      background-color: velo-color('token-color-system-page-background');
    }
  }

  &.scrollEffectOnly {
    height: 6px;
    min-height: 6px;
    position: relative;
    top: -6px;

    .mdc-top-app-bar__row {
      height: 0;
    }
  }

  .title {
    padding-left: 0.5rem;
    color: velo-color('token-color-text-default');
  }

  :global {
    .mdc-top-app-bar {
      @media (max-width: velo-breakpoint(XS)) {
        max-height: $topAppBarHeight;
        min-height: $topAppBarHeight;
      }
    }
    .mdc-drawer--closing {
      .mdc-top-app-bar__title {
        opacity: 0;
      }
    }
    .mdc-top-app-bar__section {
      padding: 2rem 0.8rem;
    }
    .mdc-top-app-bar__row {
      height: $topAppBarHeight;
    }

    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
      color: velo-color('token-color-text-default');
      &:after,
      &::before {
        background-color: velo-color('token-color-brand-primary') !important;
      }
      &:hover,
      &:focus {
        &:before,
        &:after {
          opacity: 0.12;
        }
      }
    }
  }
}
