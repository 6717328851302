@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

&.selected {
:global(.mdc-text-field__icon) {
outline: none;
color: velo-color('token-color-brand-primary') !important;
}
}
}