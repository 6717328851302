@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

max-height: 0rem;
overflow: hidden;
transition: max-height 200ms ease-in-out;
&.visible-show {
max-height: 100rem; // Edge has issues with 100% here...
}
&.visible-hide {
max-height: 0rem;
}
li {
margin: 0 0 1.3rem;
padding-bottom: 0.25rem;
color: velo-color('token-color-text-default');
}
}