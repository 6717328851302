.cls1 { /*!*/ }
.cls2 {
composes: cls1;

max-width: 500px;
margin: auto;

display: flex;
align-items: center;
flex-direction: column;
}