@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

background-color: velo-color('token-color-brand-primary-background');
border: 1px solid velo-color('token-color-border-disabled');
border-radius: 0.25rem;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 1.5rem;
cursor: pointer;
outline: none;
width: 100%;
flex: 1;

&.checked {
border-color: velo-color('token-color-brand-primary');
}

&.disabled {
opacity: 0.4;
cursor: default;
}

&.compact {
padding: 1.5rem 0.2rem;
}

&.background-themed {
background-color: velo-color('token-color-brand-primary-background');

/* https://github.com/material-components/material-components-web/issues/5044 */
&:global(.mdc-ripple-surface--primary::before),
&:global(.mdc-ripple-surface--primary::after) {
background-color: velo-color('token-color-brand-primary');
}
}

&.background-white {
background-color: white;

/* https://github.com/material-components/material-components-web/issues/5044 */
&:global(.mdc-ripple-surface--primary::before),
&:global(.mdc-ripple-surface--primary::after) {
background-color: white;
}
}
}