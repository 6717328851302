@import 'velo-variables';
@import '@design-system/tokens/typography/scss/mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include ds-typography-ellipsis;
color: velo-color('token-color-text-muted');

&:nth-child(1) {
width: 55%;
}

&:nth-child(2) {
width: 45%;
}
}