@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@media (max-width: velo-breakpoint(XS)) {
text-align: left;
}

&.compact {
margin-bottom: 0;
}

&.disabled {
color: velo-color('token-color-text-disabled');
}
}