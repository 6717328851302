/* Default hide behind layer z-index */
$velo-hidden-index: -1;

/* Default z-index */
$velo-base-index-default: 1;

/* Ranges - set aside to provide structure to Layers */

/* Range : 10-19 => full screen desktop
    default = 10
*/
$velo-full-screen-index-default: 10;

/* Range: 20 - 29 => content in front desktop
    default = 20
*/
$velo-desktop-in-front-index-default: 20;

/* 
Specific setting for header to ensure that it 
always appears above other content by default 
*/
$velo-header-default: 25;

/* Range : 30 - 39 => mobile full screen
    default = 30
*/
$velo-mobile-full-screen-index-default: 30;

/* Range : 40 - 49 => mobile in front
    default = 40
*/
$velo-mobile-in-front-index-default: 40;

/* specific for content which overlays everything else on the 
screen 
*/
$velo-over-all-default: 60;

/**
 * Snackbars that appear ontop of everything else.
 */
$velo-snackbar-notifications: 70;
