.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
margin: 0.5rem 1rem 0.5rem 2rem;
min-height: 3rem;

/* Space out content */
display: flex;
justify-content: space-between;
align-items: center;
}