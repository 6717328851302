@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
margin: 0;
padding: 0.5rem 1rem;
min-height: 5rem;
background-color: velo-color('token-color-system-page-surface');

@include from-breakpoint(XS) {
padding: 0;
flex-direction: row;
justify-content: space-evenly;
}
}