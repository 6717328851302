@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 100%;
margin-right: 1rem;
display: flex;

@media (max-width: velo-breakpoint(XS)) {
padding-right: 0;
margin-right: 0;
}

@media (min-width: velo-breakpoint(XS)) {
margin-top: 1rem;
margin-bottom: 1rem;
}
}