@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 0.9rem;
height: 38px;
padding: 0.3rem 3rem;
overflow: hidden;
&.state-active {
font-weight: bold;
color: velo-color('token-color-text-default');
}
}