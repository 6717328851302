.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin: 1rem;

&.slimline {
margin-left: 0;
margin-right: 0;
}
}