@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-right: 0.5rem;
transform: rotate(0deg);
transition: transform 300ms ease-in-out;
transform-origin: center center;

&.open {
transform: rotate(90deg);
}
}