@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 1.5rem;
min-width: 1.5rem;
height: 1.5rem;
border-radius: 50%;
font-size: 0.75rem;
line-height: 1.5rem;
text-align: center;
margin-right: 0.75rem;
color: velo-color('token-color-text-inverse');
background-color: velo-color('token-color-brand-primary');

&.disabled {
background-color: velo-color('token-color-system-disabled');
}

&.success {
background-color: velo-color('token-color-system-success-default');
}

/* Ensure the icons are the correct size. */
:global(.ds-icon) {
line-height: inherit;
font-size: 1rem;
}
}