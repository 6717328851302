@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

min-height: 20rem;
padding: 4rem 4rem 1rem 4rem;
display: flex;
flex-direction: column;
justify-content: center;
text-align: center;

@media (max-width: velo-breakpoint(XS)) {
padding: 1rem;
padding-top: 3rem;
min-height: 10rem;
}
}