@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: none;

@media (max-width: velo-breakpoint(XS)) {
display: block;
}
}