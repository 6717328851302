@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 100%;
justify-content: flex-end;

@media (max-width: velo-breakpoint(S)) {
display: none;
}
}