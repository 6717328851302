@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-top: 3rem;
margin-left: 1.5rem;
display: grid;
grid-template-columns: 2fr 1fr 3fr;
@media (max-width: velo-breakpoint(XS)) {
display: none;
}
}