@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
background-color: velo-color('token-color-system-error-lighter');
padding: 1rem;
margin: 0.5rem 0;
border-radius: 4px;
justify-content: center;
}