@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border-bottom: 1px solid velo-color('token-color-system-divider');
width: 100%;
display: inline-block;
margin-bottom: 2rem;
padding-bottom: 0.5rem;
}