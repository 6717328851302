@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

color: velo-color('token-color-text-default') !important;

&:before,
&:after {
background-color: velo-color('token-color-brand-primary') !important;
}
}