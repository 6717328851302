@import 'velo-variables';
@import 'velo-mixins';
@import '../navigationDrawer.variables';

.list {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.signoutgroup,
.footergroup {
  :global(.mdc-list) {
    padding: 0;
  }
}

.footergroup {
  .footerSpacer {
    display: none;
    height: 1rem;
  }
  .footergroupBottomGutter + .footerSpacer {
    display: inline-block;
  }
}
.hide {
  display: none;
}
.defaultgroup {
  flex: 1;
}

.copyright {
  padding: 1.8rem 1.5rem 0.8rem;
  width: auto;
  text-align: left;
  max-height: 35px;
  min-width: $navigation-width-expanded;
  opacity: 1;
  transition: $opening-transition-fadein;
  transition-delay: $animation-duration;

  &.collapsedCopyright {
    opacity: 0;
    transition: $opening-transition-fadein;
    transition-delay: 0ms;
  }
}
