@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: fixed;
top: 0;
bottom: 0;
right: 0;
left: 0;
z-index: $velo-full-screen-index-default;
}