@import 'velo-variables';
@import '@material/theme/mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-family: var(--ds-typography-font-family);

:global(.mdc-checkbox--disabled + label) {
/**
* When the checkbox is disabled ensure that the label 
* element is displayed using the theme disabled colour.
*/
color: velo-color('token-color-text-disabled');
}

/**
* Ensure checkboxes are accessible
*/
:global(.mdc-checkbox__background) {
border-color: velo-color('token-color-text-muted') !important;
}

:global(.mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background) {
border-color: velo-color('token-color-border-disabled') !important;
}

:global(.mdc-checkbox__native-control:enabled:checked
~ .mdc-checkbox__background) {
border-color: velo-color('token-color-brand-primary') !important;
}

/**
* When displaying the checkbox after the label we
* need to ensure that the label does not end up
* centered.
*/
&:global(.mdc-form-field--align-end) {
display: flex;

label {
margin-left: 0;
}
}
}