@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-left: 0;
margin-right: 0;

@media (max-width: velo-breakpoint(XS)) {
flex-direction: column;
flex-direction: column-reverse;
}
}