@import 'systemIcons';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

height: 23px;
width: 32px;
background-size: cover;
background-position: 50%;
background-repeat: no-repeat;
background-image: url(map-get($system-icons, 'LOCAL'));

/* override background-image with specific icon if it exists in the systemIcons file */
@each $icon, $value in $system-icons {
@if $icon != 'LOCAL' {
&.system-#{$icon} {
background-image: url($value);
}
}
}

&.disabled {
opacity: 0.37;
}

&.leading {
margin-top: 0px;
}

&.small {
height: 16px;
width: 24px;
}
}