@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

/* 85px = same height as text field with helper text */
height: 4.6875rem;

@media (max-width: velo-breakpoint(XS)) {
height: 3rem;
}
}