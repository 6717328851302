@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 100%;

/* Hide the clear button on Chrome/Safari */
:global(input[type='search']::-webkit-search-cancel-button) {
display: none;
}

/* Apply DS token border */
&:global(.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid)
.mdc-notched-outline__leading),
&:global(.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid)
.mdc-notched-outline__notch),
&:global(.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid)
.mdc-notched-outline__trailing) {
border-color: rgba(0, 0, 0, 0.23);
}
&:global(.mdc-text-field--outlined.mdc-text-field--disabled
.mdc-notched-outline__leading),
&:global(.mdc-text-field--outlined.mdc-text-field--disabled
.mdc-notched-outline__notch),
&:global(.mdc-text-field--outlined.mdc-text-field--disabled
.mdc-notched-outline__trailing) {
border-color: velo-color('token-color-border-disabled');
}

/* Fix floating label color */
&:global(.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid)
.mdc-floating-label) {
color: velo-color('token-color-text-default');
}
/* Fix field text color */
&:global(.mdc-text-field:not(.mdc-text-field--disabled)
.mdc-text-field__input) {
color: velo-color('token-color-text-default');
}
/* Fix disabled field label color */
&:global(.mdc-text-field--disabled .mdc-floating-label) {
color: velo-color('token-color-text-disabled');
}
/* Fix placeholder color */
&:global(.mdc-text-field:not(.mdc-text-field--disabled)
.mdc-text-field__input::placeholder) {
color: velo-color('token-color-text-muted');
}

/* Fix disabled color */
&:global(.mdc-text-field--outlined.mdc-text-field--disabled
.mdc-text-field__input) {
color: velo-color('token-color-text-disabled');
}

/* Ensure the correct font is used for the help text, label and input. */
+ :global(.mdc-text-field-helper-line > .mdc-text-field-helper-text),
:global(.mdc-floating-label),
:global(.mdc-text-field__input) {
font-family: var(--ds-typography-font-family);
}

&:global(.mdc-text-field:not(.mdc-text-field--disabled)
.mdc-text-field__icon) {
color: velo-color('token-color-text-default') !important;
}

/* Fix the floating label colour. */
/* https://github.com/material-components/material-components-web/issues/2718 */
&:global(.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid)
.mdc-floating-label) {
color: velo-color('token-color-brand-primary');
}

/* Fix the required asterisk colour. */
/* https://github.com/material-components/material-components-web/issues/2718 */
:global {
.mdc-text-field__input:required ~ {
.mdc-floating-label::after,
.mdc-notched-outline .mdc-floating-label::after {
color: velo-color('token-color-brand-primary');
}
}
}

/** Only show help text when invalid */
&.dynamicHelpText {
+ :global(.mdc-text-field-helper-line) {
display: none;
}

&:global(.mdc-text-field--invalid + .mdc-text-field-helper-line) {
display: block;
}
}
}