@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

&.type-warning,
&.type-failed {
margin: 0rem 0rem 0rem -0.625rem;
padding: 0.625rem 0rem 0.625rem 0.625rem;
}

&.type-warning {
background-color: velo-color('token-color-system-warning-lighter');
}

&.type-failed {
background-color: velo-color('token-color-system-error-lighter');
}
}