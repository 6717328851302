@import 'velo-variables';
@import 'velo-mixins';

.payeeName {
  @media (max-width: velo-breakpoint(XS)) {
    color: velo-color('token-color-text-default');
    @include velo-font-weight(bold);
  }
}

.remoteId {
  @media (max-width: velo-breakpoint(XS)) {
    display: none;
  }
}
