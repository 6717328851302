@import 'velo-variables';
@import 'velo-mixins';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
justify-content: space-between;
padding: 0.5rem 0;
color: velo-color('token-color-text-default');
list-style: none;
background-color: velo-color('token-color-system-page-surface');

& + & {
border-top: 1px solid velo-color('token-color-system-divider');
}

@include from-breakpoint(XS) {
padding: 0 1rem;
flex-direction: column;
justify-content: center;
flex: 1;

& + & {
border-top-width: 0;
border-left: 1px solid velo-color('token-color-system-divider');
}
}
}