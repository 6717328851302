@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

z-index: $velo-over-all-default;

:global(.mdc-dialog__surface) {
@media (min-width: velo-breakpoint(XS)) {
min-width: 700px;
}
}

:global(.mdc-dialog__actions) {
padding: 0 1rem 1rem;
}
}