@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: fixed;
left: 0;
top: 0;
right: 0;
bottom: 0;

display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
z-index: $velo-snackbar-notifications;
}