@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

max-width: 70%;
text-align: center;
position: relative;
padding: 1rem;
margin: 1rem 0;
background-color: velo-color('token-color-brand-primary-background');

span {
color: velo-color('token-color-brand-primary');
}

@media (min-width: velo-breakpoint(XS)) {
max-width: 464px;
}
}