@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-left: auto;
margin-right: 0;
color: velo-color(token-color-text-muted);

/* Hide icons on mobile */
@media (max-width: velo-breakpoint(XS)) {
display: none;
}
}