@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;

&.flat {
border: none;
box-shadow: none;
border-radius: 0.25rem;
margin: 0 auto;
}

&.slimline {
max-width: 50rem;
}

@mixin fullScreen {
position: fixed;
top: 0;
left: 0;
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
z-index: $velo-over-all-default;
border-radius: 0;
max-height: inherit;
}

&.fullWidth {
width: 100%;
max-width: unset;
}

&.fullScreen {
/* Full screen on mobile. */
@media (max-width: velo-breakpoint(XS)) {
@include fullScreen;
}
}

&.fullScreenSmall {
/* Full screen on mobile using small (S) breakpoint. */
@media (max-width: velo-breakpoint(S)) {
@include fullScreen;
}
}
}