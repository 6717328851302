.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@mixin no-content-border {
:global(.velo-stepper-content) {
border-left: none;
}
}

margin-bottom: 0.5rem;

&:last-child {
@include no-content-border;
}

&.last {
@include no-content-border;
}
}