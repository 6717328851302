.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border-top-left-radius: 0;
border-bottom-left-radius: 0;
padding-left: 8px;

:global(.mdc-fab__icon) {
margin-right: -12px;
margin-left: -6px;
}
}