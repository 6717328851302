@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@mixin icon($color) {
color: $color;
background-image: radial-gradient(
at center,
velo-color('token-color-system-page-background') 50%,
transparent 40%
);
font-size: 30px;
margin-right: 0.5rem;
}

&.type-success {
@include icon(velo-color('token-color-system-success-default'));
}

&.type-failure {
@include icon(velo-color('token-color-system-error-default'));
}
}