/**
SCSS definitions for reusable animations 
*/

//TODO - should maybe be moved into a mixins scss file
@mixin keyframe($animation_name) {
  @-webkit-keyframes #{$animation_name} {
    @content;
  }

  @-moz-keyframes #{$animation_name} {
    @content;
  }

  @keyframes #{$animation_name} {
    @content;
  }
}

@include keyframe(slideDown) {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@include keyframe(slideUp) {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
