@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

color: velo-color('token-color-text-default');
border-bottom: 1px solid velo-color('token-color-system-divider');
padding: 0.5rem 0 0.5rem 0;
white-space: initial;
overflow-wrap: break-word;
}