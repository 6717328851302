@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@media (max-width: velo-breakpoint(XS)) {
height: 3rem;
margin-left: 1rem;
}
}