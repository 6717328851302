// Import the MDC theme and typography overrides
@import 'modules/variables/palette';

@import '~@design-system/tokens/typography/scss/typography-utils';
@import '~@design-system/tokens/typography/css/typography-vars.css';
@import '~@design-system/tokens/typography/scss/tokens';

// Import the Material theme.
// This ensures global MDC theme classes are available.
@import '@material/theme/mdc-theme';

// Import any overrides required
@import 'overrides';
@import 'globals';

strong {
  font-weight: $token-typography-xxs-body-text-emphasised-font-weight;
}
