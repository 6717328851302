@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
flex-grow: 1;
margin: 0;
margin-block-end: 0;

@media (max-width: velo-breakpoint(XS)) {
justify-content: flex-start;
}

&:not(.disableHelperTextPadding) {
:global(.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid
+ .mdc-text-field-helper-line
.mdc-text-field-helper-text--validation-msg) {
margin-bottom: 12px;
}
}
}