@import '../../NavigationDrawer/navigationDrawer.variables.scss';

.enter {
  transition: margin-left 250ms ease-in;
  margin-left: $navigation-width-expanded !important;
}
.enter.enterActive {
  margin-left: $navigation-width-collapsed !important;
}

.exit {
  margin-left: $navigation-width-collapsed !important;
  transition: margin-left 250ms ease-in;
}

.exitDone {
  margin-left: $navigation-width-collapsed !important;
}
