.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding-left: 0;
padding-right: 0;
width: 35px;

&:hover {
cursor: pointer;
&:before,
&:after {
background-color: transparent;
}
}
}