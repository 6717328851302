@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

flex: 1;

@media (max-width: velo-breakpoint(XS)) {
padding-top: 1rem;
}
}