@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: block;
position: fixed;
z-index: $velo-base-index-default + 1;
bottom: 1rem;
right: 1rem;

@media (min-width: velo-breakpoint(XS)) {
display: none;
}
}