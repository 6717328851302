@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

max-height: 72vh;
z-index: $velo-over-all-default;

@media (min-width: velo-breakpoint(S)) {
position: absolute;
top: 56px;
right: 0px;
min-width: 42rem;
z-index: $velo-full-screen-index-default;

&.compactWidth {
min-width: 35rem;
}

&.compact {
max-height: 40vh;
}
}
}