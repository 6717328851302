$navigation-width-expanded: 300px;
$navigation-width-collapsed: 75px;
$topAppBarHeight: 65px;
$navigation-list-width: 265px;

$navigation-label-min-width: 190px;

$animation-duration: 400ms;
$content-animation-duration: 250ms;
$opening-animation: opacity $animation-duration 10ms ease-in-out;
$opening-transition-mobile: transform $animation-duration ease-in-out;
$opening-transition-fadein: opacity $animation-duration ease-in-out;
$opening-transition-desktop: width $animation-duration ease-in-out;
