@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
height: 0.3125rem;
border-radius: 1.25rem;
background-color: velo-color('token-color-system-disabled');

&.loading {
background: unset;
}
}