$animation-time: 150ms;

.enter {
  height: 0 !important;
  opacity: 0;
}

.enter.enterActive {
  height: auto !important;
  opacity: 1;
  transition: height $animation-time ease-in-out,
    opacity $animation-time ease-in-out;
}

.exit {
  opacity: 1;
  height: auto !important;
}

.exitActive {
  height: 0 !important;
  opacity: 0;
  transition: height $animation-time ease-in-out,
    opacity $animation-time ease-in-out;
}
