@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
top: 56px;
left: 0px;
right: 0px;
z-index: $velo-full-screen-index-default;
}