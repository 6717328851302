@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

&.mobileAlign-right {
@media (max-width: velo-breakpoint(XS)) {
text-align: right;
}
}
}