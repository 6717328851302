@import 'velo-variables';

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding-top: 0.5rem;
padding-bottom: 0.25rem;
color: velo-color('token-color-brand-primary');
text-align: center;
}